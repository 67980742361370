<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import _debounce from 'lodash.debounce';
import EmptyList from "@/components/widgets/empty_list"
import { required } from "vuelidate/lib/validators";
import {
  loyaltyProgramsMethods
} from "@/state/helpers";

export default {
  page: {
    title: "Go Loyalty Programs",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    EmptyList
  },
  data() {
    return {
      title: this.$t("loyalty_programs.title"),
      items: [],
			currentPage:1,
			perPage:10,
      rows:0,
			isLoading:false,
      loyaltyPrograms:[],
      confirmDelete:false,
      loyaltyProgram: {},
      empty_list_config:{
          "title" : this.$t("loyalty_programs.empty_list"),
          "linkButtonText" : this.$t("common.reset_search"),
          icon: "fa-cubes"
      },
      empty_config:{
          "title" : this.$t("loyalty_programs.empty_list"),
          "subtitle" : this.$t("loyalty_programs.empty_list_subtitle"),
          "buttonText" : this.$t("loyalty_programs.new"),
          icon: "fa-cubes"
      },
      isLocal: window.config.env == 'dev',
      query:'',
      showNewLoyaltyProgramModal: false,
      submitted: false
    };
  },
  validations: {
    loyaltyProgram: {
        name: { required},
    }
  },
  created(){
		this.debounceLoyaltyPrograms = _debounce(this.searchLoyaltyPrograms.bind(this), 1000);
	},
	mounted(){
		this.loadLoyaltyPrograms();
  },
  methods:{
    ...loyaltyProgramsMethods,

    onRemoveLoyaltyProgram(data){
      this.loyaltyProgram = data;
      this.confirmDelete = true;
    },
    onConfirmRemoveLoyaltyProgramClicked(){
      let loader = this.$loading.show();
      this.deleteLoyaltyProgram(this.loyaltyProgram._id).then(()=>{
        this.currentPage=1;
        this.loadLoyaltyPrograms();
        this.$notify({ type: 'success', text: this.$t('loyalty_programs.remove_loyalty_program_success'),title:  this.$t('loyalty_programs.title') });
        loader.hide();
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('loyalty_programs.remove_loyalty_program_error'),title:  this.$t('loyalty_programs.title') });
        loader.hide();
      })
    },

		loadLoyaltyPrograms(){
			
      let loader = this.$loading.show();
      this.isLoading = true;
      const params={
					q: `where[project]=${localStorage.getItem("current_project")}&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`,
				}
      this.getLoyaltyPrograms(params).then((loyaltyPrograms)=>{
        this.loyaltyPrograms = loyaltyPrograms.data;
        this.rows = loyaltyPrograms.totalCount;
        this.isLoading = false;
        loader.hide();
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('loyalty_programs.get_loyalty_programs_error'),title:  this.$t('loyalty_programs.title') });
        this.isLoading = false;
        loader.hide();
      })
      
    },
    searchLoyaltyPrograms(query){
      this.query = query;
			let loader = this.$loading.show();
      this.isLoading = true;
      this.query = query;
      this.empty_list_config.subtitle =  this.$t("loyalty_programs.empty_search_text").replaceAll('#', this.query);

      if(query){
        this.loyaltyPrograms=[];
        this.currentPage = 1;
				const params={
					q: `where[project]=${localStorage.getItem("current_project")}&where[name][$regex]=.*${query}.*&where[name][$options]=i&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`,
				}

        this.getLoyaltyPrograms(params).then((loyaltyPrograms)=>{
          if(loyaltyPrograms&& loyaltyPrograms.data){
            this.loyaltyPrograms= loyaltyPrograms.data;
            this.rows= loyaltyPrograms.totalCount
          }
          this.isLoading = false;
          loader.hide();
        });
      }else{
        this.isLoading = false;
        this.currentPage = 1;
        loader.hide();
        this.loadLoyaltyPrograms();
      }
    },
		onLoyaltyProgramListPageClicked(){
			this.loadLoyaltyPrograms();
		},	
    onEditLoyaltyProgramClicked(data){
      this.loyaltyProgram = Object.assign({}, data);
      this.showNewLoyaltyProgramModal = true;
    },
    
    onResetSearchClicked(){
      this.query = '';
      this.loadLoyaltyPrograms();
    },
    onNewLoyaltyProgramClicked(){
      this.loyaltyProgram = {},
      this.submitted = false;
      this.showNewLoyaltyProgramModal = true;
    },
    onConfirmCreateLoyaltyProgramClicked(){
      this.loyaltyProgram.project = localStorage.getItem("current_project");
      this.submitted = true;
      this.$v.$touch();
      
      if (this.$v.$invalid) {
        return;
      }
      
      let loader = this.$loading.show();
      if(this.loyaltyProgram._id){
        this.updateLoyaltyProgram(this.loyaltyProgram).then(()=>{
          this.loadLoyaltyPrograms();
          this.showNewLoyaltyProgramModal = false;
          this.$notify({ type: 'success', text: this.$t('loyalty_programs.update_loyalty_program_success'),title:  this.$t('loyalty_programs.title') });
        }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t('loyalty_programs.update_loyalty_program_error'),title:  this.$t('loyalty_programs.title') });
        }).finally(()=>{
          this.submitted = false; 
          loader.hide();
        })
      }else{
        this.createLoyaltyProgram(this.loyaltyProgram).then(()=>{
          this.loadLoyaltyPrograms();
          this.showNewLoyaltyProgramModal = false;
          this.$notify({ type: 'success', text: this.$t('loyalty_programs.create_loyalty_program_success'),title:  this.$t('loyalty_programs.title') });
        }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t('loyalty_programs.create_loyalty_program_error'),title:  this.$t('loyalty_programs.title') });
        }).finally(()=>{
          this.submitted = false;
          loader.hide();
        });
      }
    },
    onToggleLoyaltyProgramStatus(loyaltyProgram){
      let loader = this.$loading.show();
      const lp = Object.assign({}, loyaltyProgram);
      lp.status = loyaltyProgram.status == 1 ? 0 : 1;
      this.updateLoyaltyProgram(lp).then(()=>{
        this.loadLoyaltyPrograms();
        this.$notify({ type: 'success', text: this.$t('loyalty_programs.update_loyalty_program_success'),title:  this.$t('loyalty_programs.title') });
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('loyalty_programs.update_loyalty_program_error'),title:  this.$t('loyalty_programs.title') });
      }).finally(()=>{
        loader.hide();
      })
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class = "col">
        <div class="card mb-3">
				<div class="card-body">
          <div class="row ">
            <div class="col-sm-4 d-inline-flex">
                <div class="search-box me-2 mb-0 d-inline-block">
                  <div class="position-relative">
                      <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('common.search')"
                      @input="debounceLoyaltyPrograms($event.target.value)"
                      v-model="query"
                      />
                      <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
            </div>
            <div class="col text-sm-end">
              <button
                      type="button"
                      class="btn btn-primary mb-0 me-0"
                      @click="onNewLoyaltyProgramClicked"
                      v-if="loyaltyPrograms.length > 0">
                  <i class="mdi mdi-plus me-1"></i> {{ $t("loyalty_programs.new") }}
              </button>
            </div>
          </div>
          <EmptyList :config="empty_list_config" v-if="!isLoading && loyaltyPrograms.length == 0 && query!=''" @onButtonClicked="onResetSearchClicked" class="mt-3 mb-3"/>
          <EmptyList :config="empty_config" v-if="!isLoading && loyaltyPrograms.length == 0 && query ==''" @onButtonClicked="onNewLoyaltyProgramClicked" class="mt-3 mb-3"/>
        </div>
          <div class="col-lg-12" v-if="!isLoading && loyaltyPrograms.length>0">
            <div>
              <div class="table-responsive">
                <table class="table align-middle table-nowrap table-hover mb-0">
                  <thead class="table-light">
                    <tr>
                      <th scope="col">{{ $t("loyalty_programs.name") }}</th>
                      <th scope="col" class="text-end">{{ $t("loyalty_programs.status") }}</th>
                      <th scope="col" class="text-end"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="data in loyaltyPrograms" :key="data._id">
                      <td v-on:click="onEditLoyaltyProgramClicked(data)">
                        <h5 class="text-truncate font-size-14 mb-0 interact">
                          <a class="text-dark">{{
                            data.name
                          }}</a>
                        </h5>
                      </td>
                      <td class="text-end">
                        <span class="badge font-size-10"
                          :class="{
                          'bg-success': `${data.status}` == 1,
                          'bg-warning': `${data.status}` == 0}">
                            {{ data.status == 1 ? $t("loyalty_programs.status_active") : $t("loyalty_programs.status_inactive") }}
                        </span>
                      </td>
                      <td class="text-end">
                        <i class="fas text-primary me-2 interact" :class="data.status == 1 ? 'fa-pause' : 'fa-play'" v-on:click="onToggleLoyaltyProgramStatus(data)"></i>
                        <i class="fas fa-edit text-success me-2 interact" v-on:click="onEditLoyaltyProgramClicked(data)"></i>
                        <i class="fas fa-trash-alt text-danger me-1 interact" v-on:click="onRemoveLoyaltyProgram(data)" v-if="false"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3" v-if="rows > perPage">
          <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                          @input="onLoyaltyProgramListPageClicked"
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                      ></b-pagination>
                  </ul>
              </div>
          </div>
        </div>
        <b-modal v-model="confirmDelete" id="modal-center" centered :title="$t('common.confirm')" title-class="font-18" @ok="onConfirmRemoveLoyaltyProgramClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
            <p>{{ $t('loyalty_programs.remove_loyalty_program') }}</p>
        </b-modal>
        <b-modal
          v-model = "showNewLoyaltyProgramModal"
          title-class="font-18"
          :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')"
          :title="loyaltyProgram._id ? $t('loyalty_programs.edit') : $t('loyalty_programs.new')"
          @ok.prevent="onConfirmCreateLoyaltyProgramClicked"
          >
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="name">{{ $t('loyalty_programs.name') }}</label>
                <input type="text" 
                class="form-control" v-model="loyaltyProgram.name" 
                :placeholder="$t('loyalty_programs.name_placeholder')"
                :class="{ 'is-invalid': submitted && $v.loyaltyProgram.name.$error }" />
                <div v-if="submitted && !$v.loyaltyProgram.name.required" class="invalid-feedback">
                  {{$t('loyalty_programs.name_required')}}
                </div>
              </div>
            </div>
          </div>
        </b-modal>
      </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
</style>
